import req from './https';

// ----- 常購商品 -----

// 取得分類
export const apiGetCategory = (params) => req('post', '/Product/postgetRglCate', params);

// 設定分類
export const apiSettingCategory = (params) => req('post', '/Product/postSetCategories', params);

// 取得常購商品
export const apiGetFrequentlyBought = (params) => req('post', '/ProductWeb/postCommonProductListByStorCdxAndCustNox', params);

// 加入&移除分類
export const apiUpdateProductCategory = (params) => req('post', '/Product/postsetCategoryByProCus', params);


// ----- 商品列表 -----

// 取得商品
export const apiGetProducts = (params) => req('post', '/ProductWeb/postProductListByStorCdxAndCustNox', params);
