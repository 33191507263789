<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form
          @submit.prevent="(page = 0), getProducts()"
          @keydown.enter.prevent="(page = 0), getProducts()"
        >
          <div class="filter-card">
            <div class="filter-field">
              <label>商品查詢</label>
              <input type="text" placeholder="請輸入關鍵字" v-model="keyword" />
            </div>

            <button type="submit" class="btn btn-primary btn-sm ml-1">
              查詢
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="page-body">
      <div class="table-wrap">
        <el-table
          :data="productList"
          :key="tableKey"
          :header-cell-style="{ fontWeight: 600 }"
          style="width: 100%"
        >
          <el-table-column width="20px" />
          <el-table-column label="商品名稱">
            <template slot-scope="scope">
              {{ scope.row.parT_NAM }}<span style="font-size: 10px">{{ scope.row.uniT_NAME }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120px">
            <template slot-scope="scope">
              <eva-icon
                v-if="scope.row.shoW_PIC"
                class="icon"
                name="image"
                fill="#808080"
                width="25"
                height="25"
                @click="(productImgUrl = ''), openImage(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="數量" align="center">
            <template slot-scope="scope">
              <eva-icon
                class="icon"
                name="minus-circle"
                fill="#808080"
                width="25"
                height="25"
                @click="scope.row.salE_QTY > 1 ? scope.row.salE_QTY-- : null"
              />
              <input type="text" class="num-input" v-model="scope.row.salE_QTY" />
              <eva-icon
                class="icon"
                name="plus-circle"
                fill="#808080"
                width="25"
                height="25"
                @click="scope.row.salE_QTY++"
              />
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="add-btn"
                :style="
                  scope.row.choice === 'Y' ? { background: 'red' } : { background: '#606266' }
                "
                @click="changeCart(scope.row)"
              >
                加入購物車
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="count" @current-change="changePage" />
      </div>
    </div>

    <!-- 圖片 Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ productName }}</div>
          <div class="modal-body text-center" style="height: 640px;">
            <img v-if="hasImage" width="100%" :src="productImgUrl" @error="imageError" />
            <img v-else width="100%" src="~@/assets/img/no_food.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { apiGetProducts } from "@/apis/product";

export default {
  name: "Product",
  data() {
    return {
      tableKey: 0,
      // 客戶資訊
      customerInfo: {},

      // 關鍵字
      keyword: "",
      // 商品總筆數
      count: 0,
      // 商品列表
      productList: [],

      // 商品名稱
      productName: "",
      // 商品圖片
      productImgUrl: "",
      // 商品有圖片
      hasImage: true,

      // 當前分頁
      page: 0
    };
  },

  methods: {
    // 加入或移除商品
    changeCart(item) {
      // 購物車
      let cart = [];
      if (localStorage.CART) {
        cart = JSON.parse(localStorage.CART);
      }
      let cartLength = cart.length;

      // 商品資訊
      const product = {
        docX_NOX: "", // 預設傳入空字串
        iteM_XXX: ++cartLength, // 商品項次，從1開始
        parT_NOX: item.parT_NOX, // 商品編號
        parT_NAM: item.parT_NAM, // 商品名稱
        uniT_NAME: item.uniT_NAME, // 商品單位
        salE_QTY: item.salE_QTY // 所選取商品數量
      };

      if (cart.length > 0) {
        let sameProduct = cart.findIndex(element => element.parT_NOX === product.parT_NOX);
        sameProduct >= 0 ? cart.splice(sameProduct, 1) : cart.push(product);
      } else {
        cart.push(product);
      }

      localStorage.setItem("CART", JSON.stringify(cart));
      this.$store.dispatch("getCartProductQuantity", cart.length);

      this.updateTable();
    },

    // 點擊圖片
    openImage(product) {
      this.productName = product.parT_NAM;
      this.productImgUrl = product.imG_URL;
      this.hasImage = true;
      $("#imageModal").modal("show");
    },

    // 未找到圖片
    imageError() {
      this.hasImage = false;
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getProducts();
    },

    // 取得商品
    getProducts() {
      this.updateLoading(true);
      apiGetProducts({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        keyword: this.keyword,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.count = response.data.count;
            this.productList = response.data.datas;
            this.updateTable();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 更新 table
    updateTable() {
      this.productList.map(item => (item.salE_QTY = 1, item.choice = "N"));
      const choice = JSON.parse(localStorage.CART).map(item => {
        return {
          parT_NOX: item.parT_NOX,
          salE_QTY: item.salE_QTY
        };
      });
      this.productList.forEach((product, index, arr) => {
        choice.map(item => {
          if (item.parT_NOX === product.parT_NOX) {
            arr[index].choice = "Y";
            arr[index].salE_QTY = item.salE_QTY;
          }
        });
      });
      this.tableKey++;
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  computed: {
    ...mapGetters(["updateCart"])
  },

  watch: {
    updateCart(val) {
      if (val) {
        this.updateTable();
        this.$store.commit("UPDATE_CART", false);
      }
    }
  },

  created() {
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
    this.getProducts();
  },

  destroyed() {
    this.$store.commit("UPDATE_CART", false);
  }
};
</script>

<style lang="scss" scoped>
.num-input {
  text-align: center;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-width: 1.5px;
  background: none;
}

/deep/ .el-table,
.el-table__expanded-cell {
  font-weight: 600;
}
</style>
